<template>
  <div class="layout--full-page">
    <router-view></router-view>

    <EmbedWidget v-if="isEmbedWidget" :isAuth="false" />
  </div>
</template>

<script>
import EmbedWidget from '@/layouts/components/EmbedWidget.vue'

export default {
  components: {
    EmbedWidget
  },

  data: () => ({
    isEmbedWidget: false,
    suitableRoutes: ['page-login', 'page-forgot-password', 'page-register']
  }),

  created() {
    if (this.$router.currentRoute && this.$router.currentRoute.name && this.suitableRoutes.includes(this.$router.currentRoute.name)) {
      this.isEmbedWidget = true
    }
  },

  mounted() {
    const fullPageLayout = document.querySelector('.layout--full-page')
    if (fullPageLayout) {
      fullPageLayout.addEventListener('click', function () {
        const videoElement = document.querySelector('.dialog-video')
        if (videoElement) {
          videoElement.play()
        }
      })
    }
  }
}
</script>
